import React from 'react';
import { CalendarOutlined } from '@ant-design/icons';
import meet_icon from '../../../../assets/icons/meet_icon.png';
import { useDispatch } from 'react-redux';
import { setActiveItem } from '../../../../store/slices/bottomBarSlice';
import { motion } from 'framer-motion';

const DailySession = () => {
    const dispatch = useDispatch();

    const containerVariants = {
        hidden: { opacity: 0, x: -100 },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                type: 'spring',
                stiffness: 100,
                damping: 20,
            },
        },
        shake: {
            x: [0, -10, 10, -10, 10, -10, 10, 0],
            transition: {
                duration: 0.6,
            },
        },
    };

    return (
        <motion.div
            variants={containerVariants}
            initial="hidden"
            animate="visible"
            whileTap="shake"
            onClick={() => { dispatch(setActiveItem('sessions')) }}
            className="bg-[#FEF3E7] m-4 p-4 rounded-lg flex items-center justify-between cursor-pointer sessions-main-tour"
        >
            <div>
                <h2 className="text-2xl font-bold text-[#573926]">Bugungi sessiya</h2>
                <p className="text-gray-700 mb-2 text-[14px] mt-2">Bugungi sessiya uchun</p>
                <div className="text-orange-500 font-bold flex items-center mt-6">
                    Xozir o’tish <CalendarOutlined className="ml-1" />
                </div>
            </div>
            <div className="text-orange-500 text-6xl mt-6">
                <img src={meet_icon} alt="session icon" className="w-[100px] h-[100px]" />
            </div>
        </motion.div>
    );
};

export default DailySession;
