import React, { useState } from 'react';
import { motion } from 'framer-motion';

// Аккордеон компоненти
const Accordion = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => setIsOpen(!isOpen);

    return (
        <div className="mb-4">
            <motion.div
                className="bg-gray-600 text-white p-4 rounded-lg cursor-pointer"
                onClick={toggle}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
            >
                <h3 className="text-xl font-semibold">{title}</h3>
            </motion.div>
            {isOpen && (
                <motion.div
                    className="bg-gray-100 text-black p-4 rounded-lg mt-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {children}
                </motion.div>
            )}
        </div>
    );
};

// Asosiy maslahatlar va tavsiyalar
const Recommendations = () => {
    return (
        <div className="p-4 bg-gradient-to-r h-full from-gray-300 to-gray-200 text-black rounded-lg">
            <h2 className="text-2xl font-bold mb-6">Maslahatlar va tavsiyalar</h2>
            <Accordion title="Agar siz yolg‘izlikni his qilsangiz">
                <p>
                    Ba’zida biz hammasi yolg‘iz his qilamiz, lekin bu jarayonni yengish mumkin. Do‘stlaringiz yoki
                    oilangizga murojaat qiling, sevimli mashg‘ulotingiz bilan shug‘ullaning yoki yangi odamlar bilan
                    tanishish uchun volontyorlik tashkilotlarini qidiring.
                </p>
            </Accordion>

            <Accordion title="Agar sizda depressiya bo‘lsa">
                <p>
                    Depressiya — bu kuchsizlik emas, balki kasallikdir. Mutaxassisga murojaat qiling va davolanish
                    mumkinligini yodda tuting. Hech qanday his-tuyg‘u abadiy davom etmaydi. O‘z his-tuyg‘ularingizni
                    tan oling va yordam so‘rashdan qo‘rqmang.
                </p>
            </Accordion>

            <Accordion title="Agar siz uyda zo‘ravonlikka duchor bo‘lsangiz">
                <p>
                    Agar xavf ostida bo‘lsangiz, darhol yordam so‘rang. Ishonch telefoniga qo‘ng‘iroq qiling yoki
                    boshpana izlang. Eslatma: siz sevgi va hurmatga loyiqsiz. Hech qanday munosabatlar og‘riq keltirmasligi kerak.
                </p>
            </Accordion>
        </div>
    );
};

export default Recommendations;
