import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from './components/Header/Header';
import BottomBar from './components/Bottom/Bottom';
import Main from './pages/Main/Main';
import Sessions from "./pages/Sessions/Sessions";
import Guides from "./pages/Guides/Guides";
import Tests from "./layouts/Tests/Tests";
import Auth from "./components/Auth/Auth";
import { loginUser, decryptData } from './components/Auth/AuthService';
import { fetchCategories } from "./store/slices/categoriesSlice";
import { steps } from "./TourData/data";
import AppTour from "./TourData/AppTour";
import { STATUS } from "react-joyride";
import { setActiveItem } from "./store/slices/bottomBarSlice";
import Chat from "./components/Chat/Chat";
import { getMyResults } from "./api/Tets/Tests";
import { setUserScores } from "./store/slices/userScoresSlice";
import { handleResize } from "./utils";

function App() {
    const activeItem = useSelector((state) => state.bottomBar.activeItem);
    const dispatch = useDispatch();
    const [runTour, setRunTour] = useState(true);
    const [isPermissionGranted, setIsPermissionGranted] = useState(false);
    const [showPermissionModal, setShowPermissionModal] = useState(false);
    const isAuthenticated = localStorage.getItem('token');
    const rememberMe = localStorage.getItem('remember_me') === 'true';
    const bottomBarRef = useRef(null);

    if (!localStorage.getItem('avatar')) {
        localStorage.setItem('avatar', 'avatar-1');
    }

    const getUserScore = async () => {
        if (localStorage.getItem('token')!==null){
            const data = await getMyResults();
            dispatch(setUserScores(data));
        }

    };

    // Проверка разрешений при загрузке приложения
    useEffect(() => {
        const checkPermissions = async () => {
            try {
                await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                setIsPermissionGranted(true);
            } catch (error) {
                setIsPermissionGranted(false);
                setShowPermissionModal(true);  // Показать модальное окно, если разрешений нет
            }
        };

        checkPermissions();
        getUserScore();
    }, []);

    // Обработка автоматического логина
    useEffect(() => {
        if (rememberMe) {
            const encryptedLogin = localStorage.getItem('login');
            const encryptedPassword = localStorage.getItem('password');

            if (encryptedLogin && encryptedPassword) {
                const login = decryptData(encryptedLogin);
                const password = decryptData(encryptedPassword);

                loginUser(login, password)
                    .then(data => {
                        localStorage.setItem('token', data.token);
                        // Перенаправление или другое действие после успешного логина
                    })
                    .catch(error => {
                        console.error('Error during auto login:', error);
                    });
            }
        }
    }, [dispatch, rememberMe]);

    useEffect(() => {
        const lang = localStorage.getItem('lang') || 'uz';
        dispatch(fetchCategories(lang));
    }, [dispatch]);

    if (!isAuthenticated && !rememberMe) {
        return <Auth />;
    }

    if (!localStorage.getItem('lang')) {
        localStorage.setItem('lang', 'uz');
    }

    const contentHandler = () => {
        switch (activeItem) {
            case 'home':
                return <Main />;
            case 'sessions':
                return <Sessions />;
            case 'tests':
                return <Guides />;
            case 'guide_page':
                return <Tests />;
            case 'messages':
                return <Chat token={localStorage.getItem('token')} />;
            default:
                return <Main />;
        }
    };

    const handleJoyrideCallback = (data) => {
        const { status, index } = data;
        if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
            setRunTour(false);
        }
    };

    // Запрос разрешений на камеру и микрофон через кнопку
    const requestPermissions = async () => {
        try {
            await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
            setIsPermissionGranted(true);
            setShowPermissionModal(false);  // Закрываем модальное окно после получения разрешений
        } catch (error) {
            console.error('Error requesting permissions:', error);
        }
    };

    return (
        <div style={{ paddingBottom: handleResize() }} className="w-screen  flex flex-col overflow-hidden">
            <Header />
            <AppTour steps={steps} run={runTour} callback={handleJoyrideCallback} />
            <div className="flex-grow">{contentHandler()}</div>
            <BottomBar />

            {/* Модальное окно для запроса разрешений */}
            {showPermissionModal && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0,0,0,0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                    <div style={{
                        backgroundColor: 'white',
                        padding: '20px',
                        borderRadius: '5px',
                        textAlign: 'center'
                    }}>
                        <h2>Необходим доступ к камере и микрофону</h2>
                        <p>Пожалуйста, предоставьте доступ к камере и микрофону для продолжения.</p>
                        <button onClick={requestPermissions} style={{ padding: '10px 20px', borderRadius: '5px', backgroundColor: '#007BFF', color: 'white', border: 'none', cursor: 'pointer' }}>
                            Дать доступc
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
}

export default App;
