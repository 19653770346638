import React, { useState, useEffect, useRef } from 'react';
import io from 'socket.io-client';
import { getMessages as apiGetMessages, markAsRead } from '../../api/messaging/messageApi';
import { getPsychologists } from '../../api/psychologs/psycholosApi';
import { Button, Avatar, Badge, Typography, message } from 'antd';
import { SendOutlined, UserOutlined } from '@ant-design/icons';
import TextArea from 'antd/es/input/TextArea';
import { BiArrowBack } from 'react-icons/bi';
import VideoChat from './video/VideoChat';
import calling_sound from '../../assets/sound/calling_soung.mp3';

const { Text } = Typography;

const Chat = ({ token }) => {
    const [messages, setMessages] = useState([]);
    const [content, setContent] = useState('');
    const [selectedPsychologist, setSelectedPsychologist] = useState(null);
    const [socket, setSocket] = useState(null);
    const [psychologists, setPsychologists] = useState([]);
    const messagesEndRef = useRef(null);
    const [messageApi, contextHolder] = message.useMessage();
    const [isVideoChat, setIsVideoChat] = useState(false);

    const [calling, setCalling] = useState(false);
    const [receivingCall, setReceivingCall] = useState(false);
    const [caller, setCaller] = useState('');
    const [callerSignal, setCallerSignal] = useState(null);

    useEffect(() => {
        const socketInstance = io('https://service.lideryam.com', {
            path: '/socket.io',  // Укажи правильный путь для Socket.io
            query: { token },
            transports: ['websocket'],  // Явно укажи, что используешь WebSocket транспорт
        });

        setSocket(socketInstance);

        socketInstance.on('connect', () => {
            console.log('Connected to server');
        });

        socketInstance.on('message', (message) => {
            if (message.sender_id === selectedPsychologist?.id || message.recipient_id === selectedPsychologist?.id) {
                setMessages((prevMessages) => [...prevMessages, message]);
            }
        });

        socketInstance.on("callIncoming", (data) => {
            setReceivingCall(true);
            setCaller(data.from);
            setCallerSignal(data.signal);
            setIsVideoChat(true);
            message.info('calling');
        });

        return () => {
            socketInstance.disconnect();
        };
    }, [token, selectedPsychologist]);

    useEffect(() => {
        const fetchPsychologists = async () => {
            try {
                const response = await getPsychologists(token);
                setPsychologists(response);
            } catch (error) {
                console.error('Failed to fetch psychologists:', error);
            }
        };

        fetchPsychologists();
    }, [token]);

    useEffect(() => {
        if (selectedPsychologist) {
            const fetchMessages = async () => {
                try {
                    const response = await apiGetMessages(token);
                    const filteredMessages = response.data.filter(
                        (msg) => msg.sender_id === selectedPsychologist.id || msg.recipient_id === selectedPsychologist.id
                    ).reverse();
                    setMessages(filteredMessages);

                    const unreadMessages = filteredMessages.filter((msg) => !msg.is_read);
                    if (unreadMessages.length > 0) {
                        markMessagesAsRead(unreadMessages);
                    }
                } catch (error) {
                    console.error('Failed to fetch messages:', error);
                }
            };

            fetchMessages();
        }
    }, [selectedPsychologist, token]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [messages]);

    const markMessagesAsRead = async (unreadMessages) => {
        try {
            for (const message of unreadMessages) {
                await markAsRead(token, message.id);
                message.is_read = true;
            }
            setMessages([...messages]);
        } catch (error) {
            console.error('Failed to mark messages as read:', error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            if (socket) {
                socket.emit('sendMessage', { recipient_id: selectedPsychologist.id, content });
                console.log('Message sent:', { recipient_id: selectedPsychologist.id, content });
            }
            setContent('');
        } catch (error) {
            console.error('Failed to send message:', error);
        }
    };

    const handleUserSelect = (user) => {
        setSelectedPsychologist(user);
        setMessages([]);
    };

    const handleBackToList = () => {
        setSelectedPsychologist(null);
    };

    const getUnreadCount = (psychologistId) => {
        return messages.filter((msg) => msg.sender_id === psychologistId && !msg.is_read).length;
    };

    return (
        <div className="w-full h-[90vh] flex flex-col p-2 md:p-4 bg-[#FEF3E7]">
            {contextHolder}
            {isVideoChat ? (
                <VideoChat
                    socket={socket}
                    recipientId={selectedPsychologist?.id}
                    calling={calling}
                    caller={caller}
                    callerSignal={callerSignal}
                    receivingCall={receivingCall}
                />
            ) : !selectedPsychologist ? (
                <>
                    <Typography.Title level={4}>Список психологов</Typography.Title>
                    <div className="w-full h-full flex flex-col md:flex-row">
                        <div className="w-full p-2 md:p-4 flex flex-col overflow-auto">
                            {psychologists.map((user) => (
                                <div
                                    key={user.id}
                                    onClick={() => handleUserSelect(user)}
                                    className="cursor-pointer mb-2 w-full flex items-center p-2 bg-[#FFEBCC] rounded-lg"
                                >
                                    <Badge >
                                        <Avatar size="large" icon={<UserOutlined />} />
                                    </Badge>
                                    <div className="ml-4">
                                        <Typography className="text-[#573926] font-bold">{user.first_name}</Typography>
                                        <Typography className="text-[#573926]">{user.phone_number}</Typography>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            ) : (
                <>
                    <div className="fixed flex items-center top-0 left-0 right-0 z-10 bg-[#fff] p-2 shadow-md">
                        <Button type="primary" onClick={handleBackToList} icon={<BiArrowBack />} className="mb-2 bg-blue-700" />
                        <Typography.Title className="ml-4" level={4}>
                            Чат с {selectedPsychologist.first_name}
                        </Typography.Title>
                    </div>
                    <div className="flex-1 p-2 mt-16 flex flex-col">
                        <div className="flex-1 overflow-auto mb-4">
                            {messages.map((message, index) => (
                                <div
                                    key={index}
                                    className={`mb-2 flex ${
                                        message.sender_id === selectedPsychologist?.id ? 'justify-start' : 'justify-end'
                                    }`}
                                >
                                    <div
                                        className={`max-w-xs p-2 rounded-xl shadow-md ${
                                            message.sender_id === selectedPsychologist?.id
                                                ? 'bg-gray-200 text-left'
                                                : 'bg-blue-500 text-white text-right'
                                        }`}
                                    >
                                        {message.content}
                                    </div>
                                </div>
                            ))}
                            <div ref={messagesEndRef} />
                        </div>
                        <form onSubmit={handleSubmit} className="flex items-center mb-2">
                            <TextArea
                                rows={1}
                                value={content}
                                onChange={(e) => setContent(e.target.value)}
                                placeholder="Напишите сообщение..."
                                className="flex-1"
                            />
                            <Button
                                type="primary"
                                htmlType="submit"
                                className="ml-4 h-full bg-blue-700"
                                icon={<SendOutlined />}
                            />
                        </form>
                    </div>
                </>
            )}
        </div>
    );
};

export default Chat;

const userCard = (name, phone) => {
    return (
        <div className="w-full h-[100px] bg-[#ffb25f] rounded-lg flex items-center p-2">
            <Avatar icon={<UserOutlined />} />
            <div className="ml-4">
                <Typography className="text-white">{name}</Typography>
                <Typography className="text-white">{phone}</Typography>
            </div>
        </div>
    );
};
