import React, { useEffect, useState } from 'react';
import { Typography, Button } from 'antd';
import { useSelector } from 'react-redux';
import GuideCard from './items/GuidesCard/GuidesCard';
import {getDrawingTEst} from "../../../../api/Tets/Tests";

const GuidesList = () => {
    const testCategories = useSelector((state) => state.categories.testCategories);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [guides, setGuides] = useState([]);
    const userScores = useSelector((state) => state.user_scores.user_scores);


    useEffect(() => {
        if (testCategories.length > 0) {
            setSelectedCategory(testCategories[0].id); // Выбираем первую категорию по умолчанию
            setGuides(testCategories[0].contents); // Загружаем контент первой категории
        }
    }, [testCategories]);




    const handleCategoryClick = (categoryId) => {
        const selectedCategory = testCategories.find(category => category.id === categoryId);
        setSelectedCategory(categoryId);
        setGuides(selectedCategory.contents);
    };

    // Проверка, завершён ли гайд
    const isGuideCompleted = (guideId) => {
        return userScores.some(score => score.content_id === guideId);
    };

    return (
        <div className="p-4">
            <Typography className="text-xl font-thin mb-4">Siz uchun maxsus metodikalar</Typography>
            <div className="flex space-x-2 mb-4">
                {testCategories.map(category => (
                    <Button
                        key={category.id}
                        type={selectedCategory === category.id ? 'primary' : 'default'}
                        onClick={() => handleCategoryClick(category.id)}
                        className="rounded-xl"
                    >
                        {category.name}
                    </Button>
                ))}
            </div>
            <div className="space-y-4">
                {guides.map((guide, index) => (
                    <GuideCard
                        key={index}
                        guide={guide}
                        isCompleted={isGuideCompleted(guide.id)} // Проверяем, завершён ли гайд
                    />
                ))}
                <GuideCard

                    isImage={true}
                />
            </div>
        </div>
    );
};

export default GuidesList;
