import React from 'react';
import { IoMdPlayCircle } from "react-icons/io";
import moment from 'moment';

const CurrentSessionCard = ({ session }) => {
    const formattedTime = `${moment(session.session_time).format('HH:mm')} - ${moment(session.session_time).add(1, 'hours').format('HH:mm')}`;

    return (
        <div className="bg-orange-100 p-6 rounded-xl shadow-md mb-4">
            <h2 className="text-2xl font-bold text-[#573926]">Bugun sizda sessiya bor</h2>
            <p className="text-[#573926] mt-2">Pycholog {session.psychologist.first_name} {session.psychologist.last_name}</p>
            <p className="text-brown-700 mt-2">{formattedTime} vaqtida</p>
            <a href="#" className="text-orange-500 font-bold flex items-center mt-4">
                Qabul qilish <IoMdPlayCircle className="ml-2 text-xl" />
            </a>
        </div>
    );
};

export default CurrentSessionCard;
