import React, { useState } from 'react';
import { Typography } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import { motion, AnimatePresence } from 'framer-motion';
import SessionCard from './items/SessionsCard';
import collapse_id   from '../../../../assets/images/collapse_img.png';


const SessionsList = ({sessions}) => {
    const [isOpen, setIsOpen] = useState(true);
    console.log(sessions)
    return (
        <div className="p-4">
            <div className="flex justify-between items-center mb-4 cursor-pointer" onClick={() => setIsOpen(!isOpen)}>
                <Typography className="text-2xl font-bold text-brown-900">Barcha sessiyalar</Typography>
                {isOpen ? <UpOutlined className="text-gray-500" /> : <DownOutlined className="text-gray-500" />}
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        transition={{ duration: 0.3 }}
                    >
                        {sessions.map((session, index) => (
                            <SessionCard key={index} session={session} />
                        ))}
                    </motion.div>
                )}
            </AnimatePresence>
            {!isOpen &&(
                <div>
                    <img   src={collapse_id} alt={'collapse'}/>
                </div>
            )}
        </div>
    );
};

export default SessionsList;
