import { useEffect, useState, useRef } from "react";
import { Howl } from "howler";
import {
    IoPlayCircle,
    IoPauseCircle,
    IoShuffleOutline,
    IoPlaySkipBackOutline,
    IoPlaySkipForwardOutline,
    IoArrowBack
} from 'react-icons/io5';
import playersounds from "../../assets/sound/player/playersounds";
import wallpapers from "../../assets/wallpapers/wallpapers";

const categories = ["ocean", "rain", "relax", "sleep", "wood"];

const getRandomWallpaper = (category) => {
    const categoryWallpapers = wallpapers[category];
    if (!categoryWallpapers || categoryWallpapers.length === 0) return null;
    const randomIndex = Math.floor(Math.random() * categoryWallpapers.length);
    return categoryWallpapers[randomIndex];
};

function getAverageColor(imageUrl, callback) {
    const img = new Image();
    img.crossOrigin = "Anonymous";
    img.src = imageUrl;
    img.onload = () => {
        const canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        const data = ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        let r = 0, g = 0, b = 0;
        for (let i = 0; i < data.length; i += 4) {
            r += data[i];
            g += data[i + 1];
            b += data[i + 2];
        }
        r = Math.floor(r / (data.length / 4));
        g = Math.floor(g / (data.length / 4));
        b = Math.floor(b / (data.length / 4));
        callback(`rgb(${r}, ${g}, ${b})`);
    };
};

export default function MobileAudioPlayer({ onClose }) {
    const [currentCategory, setCurrentCategory] = useState("ocean");
    const [wallpaper, setWallpaper] = useState(null);
    const [bgColor, setBgColor] = useState("#11111b");
    const [sound, setSound] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [duration, setDuration] = useState(0);
    const [trackIndex, setTrackIndex] = useState(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const drawerRef = useRef(null);

    useEffect(() => {
        const selected = getRandomWallpaper(currentCategory);
        setWallpaper(selected);
        setTrackIndex(0);
        setIsPlaying(false);
        if (selected) {
            getAverageColor(selected, (color) => setBgColor(color));
        }
    }, [currentCategory]);

    useEffect(() => {
        let interval;
        if (sound && isPlaying) {
            interval = setInterval(() => {
                setProgress(sound.seek());
            }, 500);
        }
        return () => clearInterval(interval);
    }, [sound, isPlaying]);

    useEffect(() => {
        const handleTouchMove = (e) => {
            if (!drawerRef.current) return;
            const touchY = e.touches[0].clientY;
            if (touchY > window.innerHeight * 0.6) {
                setDrawerOpen(false);
            } else {
                setDrawerOpen(true);
            }
        };
        const drawer = drawerRef.current;
        if (drawer) {
            drawer.addEventListener("touchmove", handleTouchMove);
        }
        return () => {
            if (drawer) drawer.removeEventListener("touchmove", handleTouchMove);
        };
    }, []);

    const formatTime = (sec) => {
        if (!sec || isNaN(sec)) return "0:00";
        const minutes = Math.floor(sec / 60);
        const seconds = Math.floor(sec % 60).toString().padStart(2, "0");
        return `${minutes}:${seconds}`;
    };

    const playSound = (index) => {
        if (sound) sound.stop();
        const categorySounds = playersounds[currentCategory];
        if (!categorySounds || !categorySounds[index]) return;

        const audio = new Howl({
            src: [categorySounds[index]],
            html5: true,
            onload: () => setDuration(audio.duration()),
            onend: () => setIsPlaying(false),
        });

        setSound(audio);
        setTrackIndex(index);
        audio.play();
        setIsPlaying(true);
    };

    const handlePlay = () => playSound(trackIndex);
    const handlePause = () => sound && sound.pause() && setIsPlaying(false);
    const handleResume = () => sound && sound.play() && setIsPlaying(true);

    const handleNext = () => {
        const nextIndex = (trackIndex + 1) % playersounds[currentCategory].length;
        playSound(nextIndex);
    };

    const handlePrev = () => {
        const prevIndex = (trackIndex - 1 + playersounds[currentCategory].length) % playersounds[currentCategory].length;
        playSound(prevIndex);
    };

    const handleSeek = (e) => {
        const bar = e.currentTarget;
        const rect = bar.getBoundingClientRect();
        const percent = (e.clientX - rect.left) / rect.width;
        const newTime = percent * duration;
        sound.seek(newTime);
        setProgress(newTime);
    };

    return (
        <div
            className="fixed inset-0 z-[9999] w-full min-h-screen text-white overflow-hidden"
            style={{ backgroundColor: bgColor }}
        >
            <div className="w-full max-w-md mx-auto p-4 min-h-screen flex flex-col justify-start relative">
                {/*<div className="absolute top-4 left-4 z-50">*/}
                {/*    <button*/}
                {/*        onClick={onClose || (() => window.history.back())}*/}
                {/*        className="bg-white text-black p-2 rounded-full shadow-lg"*/}
                {/*    >*/}
                {/*        <IoArrowBack size={24} />*/}
                {/*    </button>*/}
                {/*</div>*/}

                <div className="rounded-2xl overflow-hidden mb-6 mt-16">
                    <img
                        src={wallpaper}
                        alt="Album"
                        className="w-full h-64 object-cover rounded-xl"
                    />
                </div>

                <div className="text-center mb-6">
                    <h2 className="text-xl font-bold">Nature Sound</h2>
                    <p className="text-gray-200 capitalize">{currentCategory}</p>
                </div>

                <div className="flex flex-wrap gap-2 justify-center mb-6">
                    {categories.map((cat) => (
                        <button
                            key={cat}
                            onClick={() => setCurrentCategory(cat)}
                            className={`px-3 py-1 text-sm rounded-full ${
                                currentCategory === cat ? 'bg-white text-black' : 'bg-gray-700'
                            }`}
                        >
                            {cat}
                        </button>
                    ))}
                </div>

                <div className="flex justify-between items-center mb-6 px-4">
                    <button><IoShuffleOutline size={24} /></button>
                    <button onClick={handlePrev}><IoPlaySkipBackOutline size={24} /></button>
                    <button onClick={() => isPlaying ? handlePause() : sound ? handleResume() : handlePlay()}>
                        {isPlaying ? <IoPauseCircle size={64} /> : <IoPlayCircle size={64} />}
                    </button>
                    <button onClick={handleNext}><IoPlaySkipForwardOutline size={24} /></button>
                    <button><IoShuffleOutline style={{ transform: 'scaleX(-1)' }} size={24} /></button>
                </div>

                <div className="px-4 mb-4 cursor-pointer" onClick={handleSeek}>
                    <div className="flex justify-between text-sm text-gray-200">
                        <span>{formatTime(progress)}</span>
                        <span>{formatTime(duration)}</span>
                    </div>
                    <div className="w-full h-1 bg-gray-400 rounded mt-1">
                        <div className="h-full bg-white" style={{ width: `${(progress / duration) * 100}%` }} />
                    </div>
                </div>

                <div className="text-center text-gray-300 text-sm pb-4">
                    TODO: текст / описание / название природы
                </div>
            </div>

            {/* Swipeable Drawer */}
            <div
                ref={drawerRef}
                className={`fixed bottom-0 left-0 right-0 w-full max-w-md mx-auto bg-[#1f1f2e] rounded-t-2xl shadow-2xl transition-transform duration-500 ${
                    drawerOpen ? 'translate-y-0' : 'translate-y-[65%]'
                }`}
                style={{ height: '50vh', touchAction: 'pan-y' }}
            >
                <div
                    className="w-10 h-1 bg-gray-500 rounded-full mx-auto my-2 cursor-pointer"
                    onClick={() => setDrawerOpen(!drawerOpen)}
                />
                <div className="overflow-y-auto max-h-[calc(100%-32px)] px-4 pb-4 space-y-2">
                    {(playersounds[currentCategory] || []).map((_, idx) => (
                        <div
                            key={idx}
                            onClick={() => playSound(idx)}
                            className={`cursor-pointer px-3 py-2 rounded-lg transition ${
                                trackIndex === idx ? 'bg-white text-black' : 'bg-gray-700 hover:bg-gray-600'
                            }`}
                        >
                            🎵 Sound {idx + 1}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
