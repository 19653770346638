import React, { useState } from 'react';
import {
    PlayCircleOutlined,
    AudioOutlined,
    CheckSquareFilled
} from '@ant-design/icons';
import { ImCheckboxUnchecked } from 'react-icons/im';
import { useDispatch } from 'react-redux';
import { setGuide } from '../../../../../../store/slices/cardDataSlice';
import { setActiveItem } from '../../../../../../store/slices/bottomBarSlice';
import placeholder from '../../../../../../assets/images/placeholder.jpg';
import TestImage from '../../../../../../components/TestImage/TestImage';
import { motion, AnimatePresence } from 'framer-motion';
import DemboRubinsteinTest from "../../../../../../components/DemboRubinstein/DemboRubinsteinTest";

const GuideCard = ({ isCompleted, guide, isImage }) => {
    const dispatch = useDispatch();
    const [showTestImage, setShowTestImage] = useState(false);

    const handleClick = () => {
        if (!isImage) {
            dispatch(setGuide(guide));
            dispatch(setActiveItem('guide_page'));
        } else {
            setShowTestImage(true);
        }
    };

    return (
        <>
            <div
                onClick={handleClick}
                className="relative p-4 rounded-xl shadow-md flex flex-col justify-between bg-cover bg-center text-white cursor-pointer"
                style={{
                    backgroundImage: `url(${placeholder})`,
                    backgroundSize: 'cover'
                }}
            >
                <div className="absolute inset-0 bg-black opacity-60 rounded-xl"></div>

                <div className="relative z-10 flex flex-col justify-between h-full">
                    <div className="flex justify-between items-start">
                        <div>
                            <h2 className="text-xl font-bold">{guide?.title}</h2>
                        </div>
                    </div>

                    <div className="flex items-center mt-4">
                        <PlayCircleOutlined className="text-2xl mr-2" />
                        <span className="font-bold">Play</span>
                    </div>

                    <div className="flex justify-between items-center mt-4">
                        <div className="flex items-center">
                            <AudioOutlined className="text-2xl mr-2" />
                            <span>{isCompleted}ta audio</span>
                        </div>
                        <div className="flex items-center">
                            {isCompleted ? (
                                <CheckSquareFilled className="text-xl mr-2 text-green-400" />
                            ) : (
                                <ImCheckboxUnchecked className="text-2xl mr-2" />
                            )}
                            <span>{guide?.readingTime}</span>
                        </div>
                    </div>
                </div>
            </div>

            {/* Drawer снизу - с framer-motion */}
            <AnimatePresence>
                {showTestImage && (
                    <motion.div
                        initial={{ y: '100%' }}
                        animate={{ y: 0 }}
                        exit={{ y: '100%' }}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                        className="fixed inset-0 z-[9999] bg-white flex flex-col"
                    >
                        {/* Заголовок */}
                        <div className="p-4 border-b flex justify-between items-center">
                            <span className="font-bold text-lg">Dialog</span>
                            <button
                                onClick={() => setShowTestImage(false)}
                                className="text-red-500 text-sm underline"
                            >
                                Ortga
                            </button>
                        </div>

                        {/* Контент */}
                        <div className="flex-1 overflow-y-auto p-4">

                            <DemboRubinsteinTest  />

                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

        </>
    );
};

export default GuideCard;
