import React, { useState } from "react";

const scales = [
    { key: "health", label: "Sog‘liq" },
    { key: "intellect", label: "Aql, qobiliyatlar" },
    { key: "character", label: "Xarakter" },
    { key: "reputation", label: "Obro‘-e’tibor" },
    { key: "skills", label: "Mohir qo‘llar" },
    { key: "appearance", label: "Tashqi qiyofa" },
    { key: "confidence", label: "O‘ziga ishonch" },
];

const levels = Array.from({ length: 11 }, (_, i) => 10 - i);

export default function DemboRubinsteinTest() {
    const [selected, setSelected] = useState({});

    const handleMark = (scaleKey, level, type) => {
        setSelected((prev) => ({
            ...prev,
            [scaleKey]: {
                ...prev[scaleKey],
                [type]: level,
            },
        }));
    };

    return (
        <div className="w-full px-4 py-6 bg-gray-50 min-h-screen">
            <h2 className="text-xl font-bold text-center mb-6 text-gray-800">
                Dembo–Rubinshteyn testi
            </h2>

            <div className="flex overflow-x-auto space-x-4 pb-6">
                {scales.map((scale) => (
                    <div key={scale.key} className="flex flex-col items-center min-w-[80px]">
                        <div className="relative h-[420px] w-[60px] bg-white rounded-xl shadow-md border border-gray-200 py-4 px-1 flex flex-col justify-between">
                            <div className="absolute top-4 bottom-4 left-1/2 w-[2px] bg-gray-300 -translate-x-1/2 z-0" />
                            {levels.map((level) => {
                                const current = selected[scale.key] || {};
                                return (
                                    <div key={level} className="relative z-10 flex flex-col items-center text-xs text-gray-700">
                                        <div className="flex items-center space-x-1">
                                            <input
                                                type="radio"
                                                name={`${scale.key}-self`}
                                                value={level}
                                                checked={current.self === level}
                                                onChange={() => handleMark(scale.key, level, "self")}
                                                className="w-3 h-3 accent-blue-500"
                                                title="O'zini baholash (-)"
                                            />
                                            <input
                                                type="radio"
                                                name={`${scale.key}-ideal`}
                                                value={level}
                                                checked={current.ideal === level}
                                                onChange={() => handleMark(scale.key, level, "ideal")}
                                                className="w-3 h-3 accent-green-500"
                                                title="Ideal daraja (X)"
                                            />
                                        </div>
                                        <span
                                            className={`${
                                                current.self === level || current.ideal === level
                                                    ? "text-blue-600 font-bold"
                                                    : "text-gray-600"
                                            }`}
                                        >
                      {level}
                    </span>
                                    </div>
                                );
                            })}
                        </div>

                        <div className="mt-2 text-center text-sm text-gray-800 w-[80px] leading-snug break-words">
                            {scale.label}
                        </div>
                    </div>
                ))}
            </div>

            {/* Natijalar */}
            <div className="mt-6 px-2">
                <h3 className="text-sm font-semibold mb-2 text-gray-800">Natijalar:</h3>
                <ul className="text-sm text-gray-700 list-disc list-inside">
                    {scales.map((scale) => {
                        const value = selected[scale.key] || {};
                        const hasBoth = value.self !== undefined && value.ideal !== undefined;
                        const farq = hasBoth ? value.ideal - value.self : null;

                        return (
                            <li key={scale.key}>
                                {scale.label}:{" "}
                                {hasBoth
                                    ? `Baholash: ${value.self} | Ideal: ${value.ideal} | Farq: ${farq}`
                                    : "tanlanmagan"}
                            </li>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
}
