import React from 'react';
import Joyride, { STATUS } from 'react-joyride';

const AppTour = ({ steps, run, callback }) => {
    return (
        <Joyride
            steps={steps}
            run={run}
            continuous
            scrollToFirstStep
            showProgress
            showSkipButton
            callback={callback}
            styles={{
                options: {
                    zIndex: 10000,
                },
            }}
            locale={{
                back: 'Назад',
                close: 'Закрыть',
                last: 'Завершить',
                next: 'Далее',
                skip: 'Пропустить',
            }}
        />
    );
};

export default AppTour;
