import React from 'react';
import { useSelector } from 'react-redux';
import placehoder from '../../../assets/images/yoga.png'
const TestHeader = () => {
    const guide = useSelector(state => state.cardData.guide);
    const api_url = process.env.REACT_APP_URL;
    if (!guide) {
        return null; // Если гида нет, ничего не рендерим
    }

    return (
        <div className="p-4 rounded-xl  border-b  flex bg-white">
            <div className="flex-grow">
                <h2 className="text-xl font-bold text-gray-900">{guide.title}</h2>
                <p className="text-gray-700 mt-2">{guide.description} </p>
            </div>
            <img src={`${api_url}${guide.cover}`} alt={guide?.title} className="w-32 h-32 rounded-xl ml-4 object-cover" />
        </div>
    );
};

export default TestHeader;
