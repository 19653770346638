import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import breath from '../../assets/games/icons/breath.png';
import focus from '../../assets/games/icons/focus.png';
import meditation from '../../assets/games/icons/meditation.png';
import mindfulness from '../../assets/games/icons/mind.png';
import banner from '../../assets/games/banner.png';
import FocusGame from "./FocusGame";
import BreathingGame from './BreathingGame'
import MeditationGame from "./MeditationGame";
import MindfulnessGame from './MindfulnessGame'

const GameList = [
    {
        key: "breathing",
        title: "To‘g‘ri nafas olish",
        description: "Stressni kamaytirish uchun mashq",
        logo: breath,
    },
    {
        key: "focus",
        title: "Diqqatni ushlash",
        description: "Diqqatni rivojlantirish uchun mini-o‘yinga",
        logo: focus,
    },
    {
        key: "meditation",
        title: "Meditatsiya",
        description: "Tinchlanish va konsentratsiya uchun mashq",
        logo: meditation,
    },
    {
        key: "mindfulness",
        title: "Hushyorlik",
        description: "Vahiyni va fikrlarni nazorat qilish mashqi",
        logo: mindfulness,
    },
];

const GameCard = ({ game, onClick }) => {
    return (
        <motion.div
            className="bg-[#ffb84d] p-6 rounded-xl shadow-md cursor-pointer hover:bg-[#ffaa33] transition-all"
            onClick={onClick}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.98 }}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
        >
            <div className="relative mb-4">
                {/* O‘yinning logotipi */}
                <motion.img
                    src={game.logo}
                    alt={game.title}
                    className="w-full h-28 object-contain rounded-lg"
                    initial={{ scale: 0.9 }}
                    animate={{ scale: 1 }}
                    transition={{ duration: 0.5 }}
                />
            </div>
            <h2 className="text-xl font-semibold text-[#1c1c1c]">{game.title}</h2>
            <p className="text-sm text-gray-600 mt-2">{game.description}</p>
        </motion.div>
    );
};

const Games = () => {
    const [selectedGame, setSelectedGame] = useState(null);

    const renderGame = () => {
        switch (selectedGame) {
            case "breathing":
                return <BreathingGame />;
            case "focus":
                return <FocusGame />;
            case "meditation":
                return <MeditationGame />;
            case "mindfulness":
                return <MindfulnessGame />;
            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen bg-[#1a202c] text-white p-6">
            {/* Asosiy banner */}
            <div className="mb-6">
                <motion.img
                    src={banner} // Bannerning manzili
                    alt="Asosiy banner"
                    className="w-full h-56 object-cover rounded-2xl shadow-lg"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.8 }}
                />
            </div>

            {/* O‘yinlar bo‘limi */}
            <h1 className="text-2xl font-bold mb-4 text-[#f9f9f9]">Psixologik Mashqlar</h1>

            {/* Kartochkalar yo‘qolish animatsiyasi */}
            <div className={`grid grid-cols-2 gap-6 ${selectedGame ? "hidden" : ""}`}>
                {GameList.map((game) => (
                    <GameCard
                        key={game.key}
                        game={game}
                        onClick={() => setSelectedGame(game.key)}
                    />
                ))}
            </div>

            {/* Tanlangan o‘yinni ko‘rsatish */}
            {selectedGame && (
                <div className="h-full relative mt-6">
                    <motion.button
                        onClick={() => setSelectedGame(null)}
                        className="absolute top-0 left-0 bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded-full z-10"
                        whileHover={{ scale: 1.1 }}
                    >
                        {'<-'}
                    </motion.button>
                    {renderGame()}
                </div>
            )}
        </div>
    );
};

export default Games;
