import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const MindfulnessGame = () => {
    const [score, setScore] = useState(0);
    const [showText, setShowText] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
            setShowText(!showText);
        }, 1000);

        return () => clearInterval(interval);
    }, [showText]);

    const handleClick = () => {
        if (showText) {
            setScore(score + 1);
        }
    };

    return (
        <div className="flex flex-col items-center justify-center h-full text-white text-center px-4">
            <h2 className="text-xl font-semibold">Hushyorlik</h2>
            <p className="mt-4">So‘z o‘zgarishi bilan bosing!</p>
            <motion.div
                className="text-3xl font-bold mt-6 cursor-pointer"
                onClick={handleClick}
                style={{ visibility: showText ? "visible" : "hidden" }}
            >
                Yangi so‘z
            </motion.div>
            <p className="mt-4">Sizning hisobingiz: {score}</p>
        </div>
    );
};

export default MindfulnessGame;
