import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";

const BreathingGame = () => {
    const [phase, setPhase] = useState("inhale");
    const [animationDuration, setAnimationDuration] = useState(4000);

    useEffect(() => {
        const phases = ["inhale", "hold", "exhale"];
        let index = 0;

        const interval = setInterval(() => {
            index = (index + 1) % phases.length;
            setPhase(phases[index]);
            setAnimationDuration(
                phases[index] === "inhale"
                    ? 4000
                    : phases[index] === "hold"
                        ? 7000
                        : 8000
            );
        }, animationDuration);

        return () => clearInterval(interval);
    }, [phase, animationDuration]);

    const getLabel = () => {
        switch (phase) {
            case "inhale":
                return "Nafas oling...";
            case "hold":
                return "Nafasni ushlab turing...";
            case "exhale":
                return "Nafasni chiqarib yuboring...";
            default:
                return "";
        }
    };

    return (
        <div className="flex flex-col items-center justify-center  h-[400px] rounded-lg  bg-gradient-to-r from-blue-500 to-purple-500 text-white text-center px-4">
            <motion.div
                animate={{
                    scale: phase === "inhale" ? 1.5 : phase === "exhale" ? 0.8 : 1,
                }}
                transition={{ duration: animationDuration / 1000, ease: "easeInOut" }}
                className="w-40 h-40 bg-white rounded-full mb-6 flex items-center justify-center"
            >
                <div
                    className={`w-24 h-24 rounded-full ${
                        phase === "inhale"
                            ? "bg-green-400"
                            : phase === "hold"
                                ? "bg-yellow-400"
                                : "bg-red-400"
                    }`}
                />
            </motion.div>
            <h2 className="text-2xl font-semibold">{getLabel()}</h2>
        </div>
    );
};

export default BreathingGame;
