import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setActiveItem } from '../store/slices/bottomBarSlice';
import { motion } from 'framer-motion';
import voice_icon from '../assets/icons/voice-message.png';
const VoiceAssistant = ({ listening, onClose }) => {
    const dispatch = useDispatch();
    const [transcript, setTranscript] = useState('');

    useEffect(() => {
        const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
        recognition.lang = 'uz-UZ';
        recognition.interimResults = false;
        recognition.maxAlternatives = 1;

        recognition.onresult = (event) => {
            const speechToText = event.results[0][0].transcript.toLowerCase();
            setTranscript(speechToText);
            handleCommand(speechToText);
        };

        recognition.onspeechend = () => {
            recognition.stop();
            onClose();
        };

        recognition.onerror = (event) => {
            console.error(event.error);
            onClose();
        };

        if (listening) {
            recognition.start();
        }

        return () => {
            recognition.stop();
        };
    }, [listening]);

    const handleCommand = (speechToText) => {
        if (speechToText.includes('sessiyalar')) {
            dispatch(setActiveItem('sessions'));
        } else if (speechToText.includes('testlar') || speechToText.includes('qo\'llanmalar')) {
            dispatch(setActiveItem('tests'));
        } else if (speechToText.includes('xabarlar')) {
            dispatch(setActiveItem('messages'));
        } else if (speechToText.includes('asosiy')) {
            dispatch(setActiveItem('home'));
        }
    };

    return (
        <div className="voice-assistant">
            <h2 className="text-lg font-bold mb-4">Dialog Assistant</h2>

            <div className={`mic-visualizer ${listening ? 'listening' : ''}`}>
                <motion.div
                    className="siri-wave"
                    initial={{scale: 1}}
                    animate={{scale: [1, 1.5, 1]}}
                    transition={{duration: 1, repeat: Infinity, ease: "easeInOut"}}
                />
                <motion.div
                    className="siri-wave"
                    initial={{scale: 1}}
                    animate={{scale: [1, 2, 1]}}
                    transition={{duration: 1.5, repeat: Infinity, ease: "easeInOut"}}
                />
                <img src={voice_icon} className="w-12 h-12 rounded-xl  object-cover"/>
                <motion.div
                    className="siri-wave"
                    initial={{scale: 1}}
                    animate={{scale: [1, 2.5, 1]}}
                    transition={{duration: 2, repeat: Infinity, ease: "easeInOut"}}
                />
            </div>
        </div>
    );
};

export default VoiceAssistant;
