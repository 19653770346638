import React, { useState } from 'react';
import { loginUser, registerUser, encryptData } from './AuthService';
import { Input, Button, Checkbox, Form, Typography, Divider } from 'antd';
import { UserOutlined, LockOutlined, PhoneOutlined } from '@ant-design/icons';
import { motion } from 'framer-motion';
import logo from '../../assets/images/logo_d.png'
const { Title } = Typography;

const translations = {
    uz: {
        login: "Kirish",
        register: "Ro'yxatdan o'tish",
        switchToLogin: "Kirish sahifasiga o'tish",
        switchToRegister: "Ro'yxatdan o'tish sahifasiga o'tish",
        firstName: "Ismingiz",
        lastName: "Familiya",
        age: "Yoshingiz",
        about: "O'zingiz haqida",
        phone: "Telefon raqami",
        password: "Parol",
        confirmPassword: "Parolni tasdiqlang",
        remember: "Eslab qolish",
    },
    ru: {
        login: "Вход",
        register: "Регистрация",
        switchToLogin: "Перейти ко входу",
        switchToRegister: "Перейти к регистрации",
        firstName: "Имя",
        lastName: "Фамилия",
        age: "Возраст",
        about: "О себе",
        phone: "Номер телефона",
        password: "Пароль",
        confirmPassword: "Подтвердите пароль",
        remember: "Запомнить меня",
    },
    en: {
        login: "Login",
        register: "Register",
        switchToLogin: "Switch to Login",
        switchToRegister: "Switch to Register",
        firstName: "First Name",
        lastName: "Last Name",
        age: "Age",
        about: "About",
        phone: "Phone Number",
        password: "Password",
        confirmPassword: "Confirm Password",
        remember: "Remember Me",
    },
};

const Auth = () => {
    const [isLogin, setIsLogin] = useState(true);
    const [form] = Form.useForm();
    const [lang, setLang] = useState(localStorage.getItem("lang") || "uz");

    const t = (key) => translations[lang][key] || key;

    const handlePhoneNumber = (phone) => phone.replace(/\D/g, '').slice(-12);

    const handleSubmit = async (values) => {
        values.phone_number = handlePhoneNumber(values.phone_number);
        if (values.phone_number.length !== 12) return;

        if (isLogin) {
            try {
                const data = await loginUser(values.phone_number, values.password);
                localStorage.setItem('token', data.token);
                localStorage.setItem('login', encryptData(values.phone_number));
                localStorage.setItem('password', encryptData(values.password));
                localStorage.setItem('remember_me', values.remember_me ? 'true' : 'false');
                localStorage.setItem('d_user', JSON.stringify(data.user));
                window.location.reload();
            } catch (error) {
                console.error('Login error:', error);
            }
        } else {
            try {
                await registerUser(
                    values.first_name,
                    values.last_name,
                    values.age,
                    values.phone_number,
                    values.about,
                    values.password,
                    values.password_confirmation
                );
                setIsLogin(true);
            } catch (error) {
                console.error('Register error:', error);
            }
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-400 to-purple-500 px-4">
            <motion.div
                initial={{ opacity: 0, y: 40 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
                className="w-full max-w-sm p-6 bg-white rounded-2xl shadow-2xl"
            >
                <div className="text-center mb-6">
                    <img src={logo} alt="Logo" className="w-16 h-16 mx-auto mb-2" />
                    <Title level={3} className="!mb-1">{isLogin ? t("login") : t("register")}</Title>
                    <div className="flex justify-center mt-2 space-x-2">
                        {["uz", "ru", "en"].map((lng) => (
                            <Button
                                key={lng}
                                onClick={() => {
                                    setLang(lng);
                                    localStorage.setItem("lang", lng);
                                }}
                                className={`text-xs px-3 py-1 rounded-full ${
                                    lang === lng
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-200 text-gray-700'
                                }`}
                            >
                                {lng === "uz" && "🇺🇿 UZ"}
                                {lng === "ru" && "🇷🇺 RU"}
                                {lng === "en" && "🇬🇧 EN"}
                            </Button>
                        ))}
                    </div>
                </div>

                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleSubmit}
                    initialValues={{ remember_me: false }}
                >
                    {!isLogin && (
                        <>
                            <Form.Item name="first_name" label={t("firstName")} rules={[{ required: true }]}>
                                <Input prefix={<UserOutlined />} placeholder={t("firstName")} />
                            </Form.Item>
                            <Form.Item name="last_name" label={t("lastName")} rules={[{ required: true }]}>
                                <Input prefix={<UserOutlined />} placeholder={t("lastName")} />
                            </Form.Item>
                            <Form.Item name="age" label={t("age")} rules={[{ required: true }]}>
                                <Input type="number" placeholder={t("age")} />
                            </Form.Item>
                            <Form.Item name="about" label={t("about")}>
                                <Input.TextArea placeholder={t("about")} />
                            </Form.Item>
                            <Divider />
                        </>
                    )}

                    <Form.Item name="phone_number" label={t("phone")} rules={[{ required: true }]}>
                        <Input prefix={<PhoneOutlined />} placeholder={t("phone")} />
                    </Form.Item>
                    <Form.Item name="password" label={t("password")} rules={[{ required: true }]}>
                        <Input.Password prefix={<LockOutlined />} placeholder={t("password")} />
                    </Form.Item>

                    {!isLogin && (
                        <Form.Item name="password_confirmation" label={t("confirmPassword")} rules={[{ required: true }]}>
                            <Input.Password prefix={<LockOutlined />} placeholder={t("confirmPassword")} />
                        </Form.Item>
                    )}

                    {isLogin && (
                        <Form.Item name="remember_me" valuePropName="checked">
                            <Checkbox>{t("remember")}</Checkbox>
                        </Form.Item>
                    )}

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="w-full rounded-full py-2">
                            {isLogin ? t("login") : t("register")}
                        </Button>
                    </Form.Item>

                    <Form.Item>
                        <Button type="link" className="w-full text-center" onClick={() => setIsLogin(!isLogin)}>
                            {isLogin ? t("switchToRegister") : t("switchToLogin")}
                        </Button>
                    </Form.Item>
                </Form>
            </motion.div>
        </div>
    );
};

export default Auth;
