import React, { useEffect, useState } from "react";
import { Typography, Drawer, Spin } from "antd";
import guide_icon from '../../assets/icons/guide_icon.png';
import GuideCArd from "./components/GuideCard/GuideCArd";
import GuidesList from "./components/GuidesList/GuidesList";
import { getMyDrawRsults } from "../../api/Tets/Tests";

const baseURL = process.env.REACT_APP_URL;

export default function Guides() {
    const [results, setResults] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [viewedResults, setViewedResults] = useState([]);

    useEffect(() => {
        const stored = localStorage.getItem('viewed_drawings');
        if (stored) {
            try {
                setViewedResults(JSON.parse(stored));
            } catch (e) {
                console.error("Ошибка чтения viewed_drawings из localStorage");
            }
        }
    }, []);

    const mydraws = async () => {
        try {
            setLoading(true);
            const response = await getMyDrawRsults();
            setResults(response);
        } catch (e) {
            console.error("Ошибка загрузки результатов:", e);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        mydraws();
    }, []);

    const showDrawer = () => {
        setOpen(true);
        const updated = results.map(r => ({
            id: r.id,
            review_updated_at: r.review?.updated_at || null,
        }));
        setViewedResults(updated);
        localStorage.setItem('viewed_drawings', JSON.stringify(updated));
    };

    const closeDrawer = () => setOpen(false);

    const isResultNew = (result) => {
        const stored = viewedResults.find(v => v.id === result.id);
        const currentReviewDate = result.review?.updated_at || null;
        return !stored || stored.review_updated_at !== currentReviewDate;
    };

    const anyNewResults = results.some(isResultNew);

    return (
        <div className="w-full overflow-hidden p-4">
            <div
                onClick={showDrawer}
                className="w-full flex items-center p-4 bg-white border border-[#EADBC8] rounded-xl shadow cursor-pointer hover:bg-[#fdf6ef] transition"
            >
                <img src={guide_icon} alt="Guide Logo" width="32px" height="32px" />
                <div className="ml-4 flex flex-col flex-1">
                    <div className="flex items-center gap-2">
                        <Typography className="font-bold text-[20px] text-[#573926]">
                            Mening natijalarim
                        </Typography>
                        {anyNewResults && (
                            <span className="bg-green-500 text-white text-xs font-semibold px-2 py-0.5 rounded-full">
                    Yangi
                </span>
                        )}
                    </div>
                    <Typography className="text-sm text-gray-500">
                        Chizmalaringizni va psixolog javobini ko‘rish uchun bosing
                    </Typography>
                </div>
                <span className="text-[#573926] text-lg ml-auto">→</span>
            </div>


            <Typography className="font-bold text-[16px] mt-4">Siz uchun eng yaxshi metodika</Typography>
            <GuideCArd />
            <GuidesList />

            <Drawer
                title="Sizning chizmalaringiz"
                placement="bottom"
                height="80vh"
                onClose={closeDrawer}
                open={open}
                className="rounded-t-2xl"
            >
                {loading ? (
                    <div className="w-full h-full flex justify-center items-center">
                        <Spin size="large" />
                    </div>
                ) : (
                    <div className="space-y-4 overflow-y-auto max-h-[70vh] pr-2">
                        {results.length === 0 && (
                            <Typography>No results yet.</Typography>
                        )}
                        {results.map((result) => {
                            const isNew = isResultNew(result);
                            return (
                                <div key={result.id} className="flex items-start gap-4 p-4 bg-[#fafafa] rounded-xl shadow-sm">
                                    <img
                                        src={`${baseURL}storage/${result.image_path}`}
                                        alt="Drawing"
                                        className="w-24 h-24 object-cover rounded-md border"
                                    />
                                    <div className="flex flex-col flex-1">
                                        <div className="flex items-center gap-2">
                                            <Typography className="font-semibold text-[16px]">
                                                {result.test?.title || "Noma'lum test"}
                                            </Typography>
                                            {isNew && (
                                                <span className="bg-green-500 text-white text-[10px] px-2 py-0.5 rounded-full">
                                                    Yangi
                                                </span>
                                            )}
                                        </div>
                                        <Typography className="text-[14px] text-gray-600 mt-1">
                                            {result.test?.description}
                                        </Typography>
                                        {result.review?.comment ? (
                                            <Typography.Paragraph
                                                className="mt-2 text-[14px] text-gray-800"
                                                ellipsis={{ rows: 3, expandable: true, symbol: 'Ko‘proq' }}
                                            >
                                                {result.review.comment}
                                            </Typography.Paragraph>
                                        ) : (
                                            <Typography className="mt-2 italic text-[14px] text-orange-500">
                                                Javob ko‘rib chiqilmoqda...
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                )}
            </Drawer>
        </div>
    );
}
