import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, Modal, Typography, Button } from 'antd';
import { AiOutlineDown, AiOutlineUp } from 'react-icons/ai';
import { motion } from 'framer-motion';

const { Title } = Typography;

const NewsModal = ({ visible, onClose, newsItem }) => (
    <Modal visible={visible} onCancel={onClose} footer={null}>
        {newsItem && (
            <div>
                <img src={`${process.env.REACT_APP_URL}storage/${newsItem.cover}`} alt={newsItem.title} className="w-full" />
                <Title level={4}>{newsItem.title}</Title>
                <p>{newsItem.description}</p>
            </div>
        )}
    </Modal>
);

const NewsList = ({ isExpanded, toggleExpand }) => {
    const newsCategories = useSelector((state) => state.categories.newsCategories);
    const [selectedNews, setSelectedNews] = useState(null);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(newsCategories.length > 0 ? newsCategories[0].id : null);
    const [visibleNewsCount, setVisibleNewsCount] = useState(5);

    const handleNewsClick = (newsItem) => {
        setSelectedNews(newsItem);
        setModalVisible(true);
    };

    const closeModal = () => {
        setModalVisible(false);
        setSelectedNews(null);
    };

    const handleCategoryClick = (categoryId) => {
        setSelectedCategory(categoryId);
        setVisibleNewsCount(5); // Сброс количества видимых новостей при смене категории
    };

    const handleLoadMore = () => {
        setVisibleNewsCount(prevCount => prevCount + 5);
    };

    const filteredNews = selectedCategory
        ? newsCategories.find(category => category.id === selectedCategory).contents.flatMap(content => content.news)
        : [];

    const visibleNews = filteredNews.slice(0, visibleNewsCount);

    return (
        <motion.div
            className={`relative flex flex-col ${isExpanded ? 'h-full' : 'h-96'} transition-height duration-300 bg-gray-100 shadow-lg rounded-lg`}
            initial={{ height: 'auto' }}
            animate={{ height: isExpanded ? '100vh' : '24rem' }}
            transition={{ duration: 0.5 }}
        >
            <div className="pl-2 bg-white z-10 flex justify-between items-center rounded-t-lg">
                <div>
                    <Typography className="text-xl font-semibold mb-4">Новости</Typography>
                    <div className="flex space-x-2 mb-4 overflow-x-auto">
                        {newsCategories.map(category => (
                            <Button
                                key={category.id}
                                type={selectedCategory === category.id ? 'primary' : 'default'}
                                onClick={() => handleCategoryClick(category.id)}
                                className="rounded-full px-4 py-1"
                            >
                                {category.name}
                            </Button>
                        ))}
                    </div>
                </div>
                <Button type="primary" onClick={toggleExpand} className="mr-4 flex items-center rounded-full">
                    {isExpanded ? 'Закрыть' : 'Открыть до конца'} {isExpanded ? <AiOutlineUp className="ml-2" /> : <AiOutlineDown className="ml-2" />}
                </Button>
            </div>
            <div className="flex-grow overflow-y-auto p-4">
                <motion.div
                    className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4"
                    initial="hidden"
                    animate="visible"
                    variants={{
                        hidden: { opacity: 0, scale: 0.8 },
                        visible: {
                            opacity: 1,
                            scale: 1,
                            transition: {
                                delay: 0.3,
                                staggerChildren: 0.2,
                            },
                        },
                    }}
                >
                    {visibleNews.map((newsItem, index) => (
                        <motion.div
                            key={index}
                            variants={{
                                hidden: { opacity: 0, y: 20 },
                                visible: { opacity: 1, y: 0 },
                            }}
                        >
                            <Card
                                hoverable
                                cover={<div className="bg-cover bg-center h-48 rounded-t-lg" style={{ backgroundImage: `url(${process.env.REACT_APP_URL}storage/${newsItem.cover})` }} />}
                                onClick={() => handleNewsClick(newsItem)}
                                className="rounded-lg shadow-md"
                            >
                                <Card.Meta title={newsItem.title} description={newsItem.description} className="p-4" />
                            </Card>
                        </motion.div>
                    ))}
                </motion.div>
                {visibleNewsCount < filteredNews.length && (
                    <div className="flex justify-center mt-4">
                        <Button onClick={handleLoadMore} type="primary" className="rounded-full">
                            Загрузить еще
                        </Button>
                    </div>
                )}
                <NewsModal visible={modalVisible} onClose={closeModal} newsItem={selectedNews} />
            </div>
        </motion.div>
    );
};

export default NewsList;
