import {motion} from "framer-motion";
import React from "react";

const MeditationGame = () => {
    return (
        <div className="flex flex-col items-center justify-center h-full text-white text-center px-4">
            <h2 className="text-2xl font-semibold">Meditatsiya</h2>
            <p className="mt-4 text-sm text-gray-400">Ko'zlaringizni yuming va nafas olishga e'tiboringizni qarating.</p>
            <motion.div
                className="w-40 h-40 bg-purple-500 rounded-full mt-6"
                animate={{ scale: [1, 1.2, 1] }}
                transition={{ repeat: Infinity, duration: 5 }}
            />
        </div>
    );
};
export default MeditationGame
