import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const FocusGame = () => {
    const [clicks, setClicks] = useState(0);
    const [target, setTarget] = useState([Math.random() * 80, Math.random() * 80]);
    const [isActive, setIsActive] = useState(false); // Активный момент для быстрого клика
    const [timeLeft, setTimeLeft] = useState(30); // Таймер игры
    const [gameStarted, setGameStarted] = useState(false);
    const [showLeaderboard, setShowLeaderboard] = useState(false);

    useEffect(() => {
        let timer;
        if (gameStarted) {
            timer = setInterval(() => {
                setTimeLeft((prev) => prev - 1);
            }, 1000);
        }

        if (timeLeft === 0) {
            clearInterval(timer);
            setGameStarted(false);
            saveResult(clicks);
        }

        return () => clearInterval(timer);
    }, [gameStarted, timeLeft]);

    const handleClick = () => {
        if (isActive) {
            setClicks(clicks + 1);
            setTarget([Math.random() * 80, Math.random() * 80]); // Позиция нового круга
            setIsActive(false); // Делаем круг пассивным
        }
    };

    const startGame = () => {
        setGameStarted(true);
        setClicks(0);
        setTimeLeft(30);
        setTarget([Math.random() * 80, Math.random() * 80]);
        setShowLeaderboard(false);
    };

    const saveResult = (result) => {
        const topResults = JSON.parse(localStorage.getItem("focusResults")) || [];
        topResults.push(result);
        topResults.sort((a, b) => b - a); // Сортировка по убыванию
        topResults.splice(10); // Оставляем только топ 10

        localStorage.setItem("focusResults", JSON.stringify(topResults));
    };

    const loadLeaderboard = () => {
        const topResults = JSON.parse(localStorage.getItem("focusResults")) || [];
        return topResults;
    };

    const toggleLeaderboard = () => {
        setShowLeaderboard(!showLeaderboard);
    };

    return (
        <div className="flex flex-col items-center justify-between h-[500px] rounded-2xl bg-gradient-to-r from-indigo-500 to-purple-600 text-white text-center px-4">
            <h1 className="text-3xl font-bold mb-4">Удержи фокус!</h1>
            {!gameStarted ? (
                <button
                    onClick={startGame}
                    className="bg-yellow-500 hover:bg-yellow-400 text-white py-2 px-6 rounded-full text-xl mb-6"
                >
                    Начать игру
                </button>
            ) : (
                <div className="flex flex-col items-center mb-6">
                    <motion.div
                        style={{
                            top: `${target[1]}%`,
                            left: `${target[0]}%`,
                        }}
                        className={`absolute w-14 h-14 rounded-full cursor-pointer ${
                            isActive ? "bg-green-500" : "bg-red-500"
                        }`}
                        onClick={handleClick}
                        animate={{
                            scale: isActive ? [1, 1.2, 1] : [1, 0.8, 1],
                            opacity: isActive ? [1, 0.5, 1] : [1, 0.8, 1],
                        }}
                        transition={{
                            duration: 0.5,
                            repeat: Infinity,
                            repeatDelay: 0.5,
                        }}
                        onMouseEnter={() => setIsActive(true)}
                        onMouseLeave={() => setIsActive(false)}
                    ></motion.div>

                    <h2 className="text-xl font-semibold">Кликай по точке!</h2>
                    <p className="mt-4 text-xl">Количество кликов: {clicks}</p>
                    <p className="mt-2 text-lg">Осталось времени: {timeLeft} секунд</p>
                    <button
                        onClick={toggleLeaderboard}
                        className="mt-4 bg-purple-700 hover:bg-purple-600 text-white py-2 px-6 rounded-full text-xl"
                    >
                        {showLeaderboard ? "Скрыть топ" : "Показать топ"}
                    </button>
                </div>
            )}

            {showLeaderboard && (
                <div className="mt-6 text-xl overflow-y-auto max-h-[150px]">
                    <h3 className="font-bold">Топ 10 Результатов:</h3>
                    <ul className="mt-4">
                        {loadLeaderboard().map((score, index) => (
                            <li key={index} className="text-sm">
                                {index + 1}. {score} кликов
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default FocusGame;
