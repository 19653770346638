import React, { useState, useRef } from "react";
import { ansToDrawing } from "../../api/Tets/Tests";

export default function TestImage({ task }) {
    const [image, setImage] = useState(null);
    const [preview, setPreview] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const fileInputRef = useRef(null);

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (!file) return;

        setImage(file);
        setPreview(URL.createObjectURL(file));
        setSuccess(false); // скрыть сообщение, если юзер выбирает новый файл
    };

    const handleUpload = async () => {
        if (!image) return alert("Iltimos, rasm tanlang");

        setLoading(true);
        try {
            await ansToDrawing(image, task.id);

            // показать успех
            setSuccess(true);

            // очистить input и state
            setImage(null);
            setPreview(null);
            fileInputRef.current.value = "";
        } catch (error) {
            console.error(error);
            alert("❌ Server bilan bog‘lanishda xatolik");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4 max-w-md mx-auto bg-white shadow-md rounded-xl mt-6">
            <h2 className="text-xl font-bold mb-2">{task?.title}</h2>
            <p className="text-gray-600 mb-4">{task?.description}</p>

            {success && (
                <div className="mb-4 p-3 bg-green-100 border border-green-300 text-green-700 rounded">
                    ✅ Surat muvaffaqiyatli yuborildi!
                </div>
            )}

            <div className="mb-4">
                {preview ? (
                    <img
                        src={preview}
                        alt="Preview"
                        className="w-full h-40 object-cover rounded-lg mb-2"
                    />
                ) : (
                    <div className="w-full h-40 bg-gray-100 rounded-lg flex items-center justify-center text-gray-400">
                        Rasm yo‘q
                    </div>
                )}

                <input
                    type="file"
                    accept="image/*"
                    onChange={handleChange}
                    ref={fileInputRef}
                    className="block w-full text-sm text-gray-600 mt-2"
                />
            </div>

            <button
                onClick={handleUpload}
                disabled={loading}
                className={`w-full ${
                    loading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
                } text-white font-semibold py-2 rounded-lg transition duration-200`}
            >
                {loading ? "Yuborilmoqda..." : "Yuborish"}
            </button>
        </div>
    );
}
