import ocean1 from './ocean/1.jpg';
import ocean2 from './ocean/2.jpg';
import ocean3 from './ocean/3.jpg';
import rain1 from './rain/1.jpg';
import rain2 from './rain/2.jpg';
import relax1 from './relax/1.jpg';
import relax2 from './relax/2.jpg';
import sleep1 from './sleep/1.jpg';
import sleep2 from './sleep/2.jpg';
import wood1 from './wood/1.jpg';
import wood2 from './wood/2.jpg';

export default {
    ocean: [ocean1, ocean2],
    rain: [rain1,rain2],
    relax: [relax1,relax2],
    sleep: [sleep1,sleep2],
    wood: [wood1,wood2],
};
