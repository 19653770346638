import React, { useEffect, useRef, useState } from "react";
import SimplePeer from "simple-peer";
import { message, notification } from "antd"; // Обновлено на notification для Snackbar
import { MdOutlineWifiCalling, MdCall, MdCallEnd } from "react-icons/md";
import calling_sound from '../../../assets/sound/calling_soung.mp3';

const VideoChat = ({ socket, recipientId, calling, callerSignal, caller, receivingCall }) => {
    const [stream, setStream] = useState(null);
    const [callAccepted, setCallAccepted] = useState(false);
    const [callEnded, setCallEnded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [permissionDenied, setPermissionDenied] = useState(false);

    const userVideo = useRef();
    const partnerVideo = useRef();
    const connectionRef = useRef();
    const audioRef = useRef(new Audio(calling_sound)); // Create audio reference

    useEffect(() => {
        // Set the audio to loop
        audioRef.current.loop = true;

        const getMedia = async () => {
            try {
                const myId = JSON.parse(localStorage.getItem('user')).id;
                if (!myId) {
                    console.error("User ID not found in localStorage");
                    return;
                }

                console.log("Requesting media access...");
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
                setStream(stream);
                if (userVideo.current) {
                    userVideo.current.srcObject = stream;
                }
                setPermissionDenied(false);
                console.log("Media access granted.");
            } catch (error) {
                if (error.name === "NotAllowedError") {
                    setPermissionDenied(true);
                    console.error("Camera access denied.");
                } else {
                    console.error("Error accessing media devices.", error);
                }
            }
        };

        getMedia();

        socket.on("callAccepted", (signal) => {
            setCallAccepted(true);
            setLoading(false);
            connectionRef.current.signal(signal);
            if (audioRef.current) {
                audioRef.current.pause(); // Stop the calling sound
                audioRef.current.currentTime = 0; // Reset the audio to start
            }
        });

        return () => {
            socket.off("callIncoming");
            socket.off("callAccepted");
        };
    }, [socket]);

    useEffect(() => {
        if (receivingCall && !callAccepted) {
            if (audioRef.current) {
                audioRef.current.play(); // Play the calling sound
            }
        } else if (audioRef.current) {
            audioRef.current.pause(); // Stop the calling sound if not receiving a call
            audioRef.current.currentTime = 0; // Reset the audio to start
        }
    }, [receivingCall, callAccepted]);

    const callUser = (id) => {
        if (!stream) {
            message.error("Camera access is required.");
            return;
        }

        setLoading(true);
        const peer = new SimplePeer({
            initiator: true,
            trickle: false,
            stream: stream
        });

        peer.on("signal", (data) => {
            socket.emit("callUser", { recipient_id: id, signal: data });
        });

        peer.on("stream", (stream) => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        connectionRef.current = peer;
    };

    const answerCall = () => {
        if (!stream) {
            message.error("Camera access is required.");
            return;
        }

        setCallAccepted(true);
        const peer = new SimplePeer({
            initiator: false,
            trickle: false,
            stream: stream
        });

        peer.on("signal", (data) => {
            socket.emit("answerCall", { signal: data, to: caller });
        });

        peer.on("stream", (stream) => {
            if (partnerVideo.current) {
                partnerVideo.current.srcObject = stream;
            }
        });

        peer.signal(callerSignal);
        connectionRef.current = peer;
    };

    const leaveCall = async () => {
        try {
            setCallEnded(true);
            if (connectionRef.current) {
                try {
                    connectionRef.current.destroy();
                } catch (error) {
                    console.error("Error ending call:", error);
                }
            }

            // Display notification before reloading
            notification.info({
                message: 'Call Ended',
                description: 'The call has been ended.',
                duration: 2.5, // Duration in seconds
                onClose: () => {
                    window.location.reload();
                }
            });
        } catch (e){
            console.error(e);
        }

    };

    return (
        <div className="w-full h-full flex flex-col relative bg-black">
            {/* Partner Video Container */}
            <div className="w-full h-full flex items-center justify-center relative">
                {callAccepted && !callEnded && (
                    <video
                        playsInline
                        ref={partnerVideo}
                        autoPlay
                        className="absolute inset-0 w-full h-full object-cover"
                    />
                )}
                <div className="absolute inset-0 flex items-center justify-center text-white">
                    {callAccepted && !callEnded && <span></span>}
                </div>
            </div>

            {/* User Video Container */}
            <div className="absolute bottom-5 right-5 w-1/4 h-1/4">
                {stream && (
                    <video
                        width="100%"
                        playsInline
                        muted
                        ref={userVideo}
                        autoPlay
                        className="object-cover border-2 border-white rounded-lg"
                    />
                )}
                <div className="text-white mt-2">My Video</div>
            </div>

            {/* Answer/End Call Button */}
            {(receivingCall || callAccepted) && (
                <div
                    onClick={() => {
                        receivingCall && !callAccepted ? answerCall() : leaveCall();
                    }}
                    className={`absolute bottom-32 left-52 transform -translate-x-1/2 ${receivingCall && !callAccepted ? 'bg-blue-500' : 'bg-red-500'} rounded-full cursor-pointer z-10 flex items-center justify-center`}
                    style={{
                        width: '15vw',
                        height: '15vw',
                        maxWidth: '80px',
                        maxHeight: '80px',
                        minWidth: '60px',
                        minHeight: '60px',
                    }}
                >
                    {receivingCall && !callAccepted ? (
                        <MdCall className="text-white text-base lg:text-xl" />
                    ) : (
                        <MdCallEnd className="text-white text-base lg:text-xl" />
                    )}
                </div>
            )}

            {/* Overlay Messages */}
            <div className="absolute top-5 left-5 w-full h-full flex items-center justify-center">
                {loading && <div className="bg-gray-800 text-gray-300 p-4 rounded">Calling...</div>}
                {permissionDenied && (
                    <div className="bg-gray-800 text-red-500 p-4 rounded">
                        Camera access denied. Please enable it in your browser settings.
                    </div>
                )}
            </div>
        </div>
    );
};

export default VideoChat;
