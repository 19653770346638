import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import book from '../../../../assets/icons/list/book.png';
import player from '../../../../assets/icons/list/player.png';
import games from '../../../../assets/icons/list/games.png';
import recommendation from '../../../../assets/icons/list/recommendation.png';
import MobileAudioPlayer from "../../../../components/Player/Player";
import BookReader from "../../../../components/Book/BookReader";
import Games from "../../../../components/Games/Games";
import Recommendations from "../../../../components/Recommendations/Recommendations";
const feelings = [
    { key: 'book', color: '#ffffff', icon: book, label: 'Kitoblar' },
    { key: 'player', color: '#ffffff', icon: player, label: 'PLayer' },
    { key: 'games', color: '#ffffff', icon: games, label: 'o`yinlar' },
    { key: 'recommendation', color: '#ffffff', icon: recommendation, label: 'Tavfsiyalar' },
];

const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
};

const drawerVariants = {
    hidden: { y: '100%' },
    visible: { y: 0 },
    exit: { y: '100%' },
};

const DailyFeel = () => {
    const [selectedKey, setSelectedKey] = useState(null);

    const handleItemClick = (key) => {
        setSelectedKey(key);
    };

    const handleCloseDrawer = () => {
        setSelectedKey(null);
    };

    const renderContent = () => {
        switch (selectedKey) {
            case 'player':
                return <MobileAudioPlayer type="player" onClose={handleCloseDrawer} />;
            case 'book':
                return <BookReader />;
            case 'games':
                return <Games/>;
            case 'recommendation':
                return <Recommendations/>;
            default:
                return null;
        }
    };

    return (
        <div className="p-4 relative overflow-hidden">
            <h2 className="text-lg font-bold text-gray-900 mb-4">Bugun o`zingizni qanday his qilayapsiz ?</h2>

            <motion.div
                className="flex flex-row items-center space-x-4 w-full justify-between"
                initial="hidden"
                animate="visible"
                variants={{
                    hidden: {},
                    visible: { transition: { staggerChildren: 0.2 } },
                }}
            >
                {feelings.map((feel, index) => (
                    <motion.div
                        key={index}
                        className="flex flex-col items-center"
                        variants={itemVariants}
                        whileTap={{ scale: 0.9 }}
                        whileHover={{ scale: 1.1 }}
                        onClick={() => handleItemClick(feel.key)}
                    >
                        <div
                            className="w-16 h-16 flex items-center justify-center rounded-lg"
                            style={{ backgroundColor: feel.color }}
                        >
                            <img src={feel.icon} alt={feel.label} className="w-8 h-8" />
                        </div>
                        <span className="text-gray-500 text-[12px] mt-2">{feel.label}</span>
                    </motion.div>
                ))}
            </motion.div>

            {/* Drawer-панель */}
            <AnimatePresence>
                {selectedKey && (
                    <motion.div
                        className="fixed inset-0 bg-black bg-opacity-90 z-[9999] overflow-hidden"
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        variants={drawerVariants}
                        transition={{ duration: 0.4, ease: 'easeInOut' }}
                    >
                        {/* Кнопка закрытия над всем, не влияет на layout */}
                        <div className="absolute top-4 right-4 z-[10000]">
                            <button
                                onClick={handleCloseDrawer}
                                className="text-white text-xl px-3 py-1 rounded-full bg-red-600 hover:bg-red-700"
                            >
                                ✕
                            </button>
                        </div>

                        {/* Контейнер, занимающий всю высоту */}
                        <div className="w-full h-full">
                            <div className="w-full h-full overflow-y-auto px-2">
                                {renderContent()}
                            </div>
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>


        </div>
    );
};

export default DailyFeel;
