import ocean1 from './ocean/1.mp3';
import rain1 from './rain/1.mp3';
import relax1 from './relax/1.mp3';
import relax2 from './relax/2.mp3';
import relax3 from './relax/3.mp3';
import sleep1 from './sleep/1.mp3';
import wood1 from './wood/1.mp3';
import wood2 from './wood/2.mp3';

export default {
    ocean: [ocean1],
    rain: [rain1],
    relax: [relax1,relax2,relax3],
    sleep: [sleep1],
    wood: [wood1,wood2],
};
