import { useState } from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

import {
    Book,
    Home,
    Library,
    Globe,
    Settings,
    Search,
    X,
    ChevronLeft,
} from 'lucide-react';

import loris from '../../assets/books/childs/loris.pdf';
import monte from '../../assets/books/childs/monte.pdf';
import violence from '../../assets/books/women/домашное_насилия.pdf';
import womenPsych from '../../assets/books/women/писхология_женшины.pdf';

const categories = ['Barchasi', 'Psixologiya', 'Bolalar', 'Erkaklar', 'Ayollar'];

const books = [
    { id: 'loris', title: 'Лорис Малагуцци', category: 'Bolalar', file: loris, author: 'Reggio Studio' },
    { id: 'monte', title: 'Метод Монтессори', category: 'Bolalar', file: monte, author: 'Maria M.' },
    { id: 'violence', title: 'Домашнее насилие', category: 'Ayollar', file: violence, author: 'Психологическая служба' },
    { id: 'womenPsych', title: 'Психология женщины', category: 'Psixologiya', file: womenPsych, author: 'Духовные практики' },
];

// Функция для генерации рандомного цвета
function getRandomColor() {
    const colors = [
        '#ff9a9e', '#a18cd1', '#fbc2eb', '#f6d365',
        '#84fab0', '#cfd9df', '#fccb90', '#e0c3fc',
        '#fda085', '#ffecd2', '#a1c4fd', '#c2e9fb',
    ];
    return colors[Math.floor(Math.random() * colors.length)];
}

export default function BookReader() {
    const [selectedBook, setSelectedBook] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [categoryFilter, setCategoryFilter] = useState('Все');
    const [showUpgrade, setShowUpgrade] = useState(false);

    const defaultLayoutPluginInstance = defaultLayoutPlugin();

    const filteredBooks = books.filter(
        (b) =>
            (categoryFilter === 'Все' || b.category === categoryFilter) &&
            b.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="max-w-md mx-auto min-h-screen bg-gray-50">
            {selectedBook ? (
                <div className="fixed inset-0 z-[9999] bg-white flex flex-col">
                    <div className="flex items-center gap-3 p-4 border-b">
                        <button
                            onClick={() => setSelectedBook(null)}
                            className="bg-gray-200 p-2 rounded-full"
                        >
                            <ChevronLeft size={20} />
                        </button>
                        <h2 className="font-semibold text-lg">{selectedBook.title}</h2>
                    </div>
                    <div className="flex-1 overflow-hidden">
                        <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                            <Viewer
                                fileUrl={selectedBook.file}
                                plugins={[defaultLayoutPluginInstance]}
                            />
                        </Worker>
                    </div>
                </div>
            ) : (
                <>
                    <div className="p-6 pb-28">
                        <h1 className="text-3xl font-bold mb-4 text-gray-900">
                            Dialog Kutubxona
                        </h1>

                        {/* Категории */}
                        <div className="flex gap-2 overflow-x-auto mb-6">
                            {categories.map((cat) => (
                                <button
                                    key={cat}
                                    onClick={() => setCategoryFilter(cat)}
                                    className={`px-4 py-1.5 rounded-full text-sm whitespace-nowrap ${
                                        categoryFilter === cat
                                            ? 'bg-indigo-600 text-white'
                                            : 'bg-gray-200 text-gray-800'
                                    }`}
                                >
                                    {cat}
                                </button>
                            ))}
                        </div>

                        {/* Поиск */}
                        <input
                            type="text"
                            placeholder="🔍 Поиск книги..."
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full mb-6 px-4 py-2 rounded-lg border shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />

                        {/* Список книг */}
                        <div className="grid grid-cols-2 gap-4">
                            {filteredBooks.map((book) => (
                                <div
                                    key={book.id}
                                    onClick={() => setSelectedBook(book)}
                                    className="bg-white rounded-2xl overflow-hidden shadow hover:shadow-md transition cursor-pointer"
                                >
                                    <div
                                        className="w-full h-48 flex items-end px-3 pb-3"
                                        style={{
                                            background: `linear-gradient(135deg, ${getRandomColor()}, ${getRandomColor()})`,
                                        }}
                                    >
                                        <h3 className="text-white text-sm font-semibold truncate w-full">
                                            {book.title}
                                        </h3>
                                    </div>
                                    <div className="p-3">
                                        <p className="text-xs text-gray-600">{book.author}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>



                    {/* Модалка апгрейда */}
                    {showUpgrade && (
                        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[9999]">
                            <div className="bg-white rounded-2xl p-6 max-w-sm w-full">
                                <div className="flex justify-between items-center mb-6">
                                    <h2 className="text-xl font-bold">Upgrade Plan</h2>
                                    <button onClick={() => setShowUpgrade(false)}>
                                        <X size={20} />
                                    </button>
                                </div>
                                <div className="text-center mb-8">
                                    <div className="w-24 h-24 mx-auto mb-4 bg-indigo-100 rounded-full flex items-center justify-center">
                                        <Book className="text-indigo-600" size={32} />
                                    </div>
                                    <h3 className="text-lg font-semibold mb-2">
                                        Ad-Free Reading
                                    </h3>
                                    <p className="text-gray-600 text-sm">
                                        No more limit! Read unlimited books.
                                    </p>
                                </div>
                                <button className="w-full bg-indigo-600 text-white py-3 rounded-lg font-semibold">
                                    Upgrade for $5/month
                                </button>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
}
