import React, { useEffect, useState } from 'react';
import draw from '../../../../assets/icons/draw.png';
import { getDrawingTEst } from '../../../../api/Tets/Tests';
import TestImage from "../../../../components/TestImage/TestImage";
import { motion, AnimatePresence } from "framer-motion";

const GuideCard = () => {
    const [drawingTests, setDrawingTests] = useState([]);
    const [selectedTest, setSelectedTest] = useState(null);

    const fetchDrawingTests = async () => {
        try {
            const response = await getDrawingTEst();
            const data = Array.isArray(response?.data) ? response.data : response;
            setDrawingTests(data || []);
        } catch (error) {
            console.error("Error fetching drawing tests:", error);
        }
    };

    useEffect(() => {
        fetchDrawingTests();
    }, []);

    const handleClose = () => setSelectedTest(null);

    return (
        <>
            <div className="relative h-auto p-4 rounded-2xl shadow-lg bg-gradient-to-r from-blue-100 to-purple-100">
                <h3 className="text-[18px] font-bold text-[#1e1e1e] mb-4">✏️ Chizish testlari</h3>

                <div className="flex space-x-4 overflow-x-auto scrollbar-hide pb-1">
                    {drawingTests.map((test, index) => (
                        <div
                            key={index}
                            onClick={() => setSelectedTest(test)}
                            className="min-w-[130px] max-w-[130px] bg-white rounded-xl p-4 flex-shrink-0 shadow-md hover:shadow-xl transition-all flex flex-col items-center text-center border border-gray-100 cursor-pointer"
                        >
                            <div className="bg-blue-100 rounded-full p-3 mb-2">
                                <img src={draw} alt="draw icon" className="w-8 h-8" />
                            </div>

                            <h4 className="text-[13px] font-medium text-gray-800 leading-tight line-clamp-2">
                                {test.title}
                            </h4>
                        </div>
                    ))}
                </div>
            </div>

            {/* Drawer */}
            <AnimatePresence>
                {selectedTest && (
                    <motion.div
                        initial={{ y: "100%" }}
                        animate={{ y: 0 }}
                        exit={{ y: "100%" }}
                        transition={{ duration: 0.4, ease: "easeInOut" }}
                        className="fixed inset-0 z-[9999] bg-black bg-opacity-60 flex items-end"
                    >
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="w-full h-full bg-white rounded-t-2xl p-4 overflow-y-auto"
                        >
                            <div className="flex justify-between items-center mb-4">
                                <span className="font-bold text-lg text-gray-800">🖼 Test: {selectedTest.title}</span>
                                <button
                                    onClick={handleClose}
                                    className="text-red-500 text-sm underline"
                                >
                                    Ortga
                                </button>
                            </div>

                            <TestImage task={selectedTest} />
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default GuideCard;
